import { Row } from 'antd';
import { PageDescription, Score } from 'components';
import { FC } from 'react';

export interface InformativeBannerProps {
  pageDescriptionTitle?: string;
  pageDescription?: string;
  aquiredScore: number;
  maxScore: number;
  scoreDescription?: string;
  scoreFooter?: string;
  isLoading: boolean;
  year: number;
  showPageDescription?: boolean;
}
export const InformativeBanner: FC<InformativeBannerProps> = ({
  aquiredScore,
  maxScore,
  pageDescription = `U neemt deel om uw bedrijfsvoering te verbeteren ten gunste van de biodiversiteit, 
  waterkwaliteit, een vitale bodem en landschap. 
  Hieronder ziet u hoe goed u op dit moment scoort op de indicatoren en welke beloning u maximaal ontvangt.`,
  pageDescriptionTitle = `Welkom 
  Brabantse Biodiversiteitsmonitor Melkveehouderij`,
  scoreDescription = 'Dit is uw score over ',
  scoreFooter = 'Hoe u aan deze score komt wordt hieronder uitgelegd',
  isLoading,
  year,
  showPageDescription = true,
}) => {
  return (
    <Row gutter={20}>
      {showPageDescription && <PageDescription title={pageDescriptionTitle} description={pageDescription} />}
      <Score
        year={year}
        showPageDescription={showPageDescription}
        aquiredScore={aquiredScore}
        description={scoreDescription}
        isLoading={isLoading}
        maxScore={maxScore}
        footer={scoreFooter}
      />
    </Row>
  );
};
