import { InformativeBanner, NavBar } from 'components';
import { ScoreComparingList } from 'components/score-comparing-list';
import { FC, useEffect, useState } from 'react';
import { getTotalScoreByArray, prefixStringWith0 } from 'utilities/logic';

export const ComparePrint: FC = () => {
  const [data, setData] = useState({
    year: 0,
    points: [],
    items: [],
    greenItems: [],
    isLoading: false,
    userInfo: { naam: '', kvkNummer: '' },
  });
  useEffect(() => {
    (async () => {
      // @ts-ignore
      if (window.getData) {
        // @ts-ignore
        setData(await window.getData());
      }
    })();
  }, []);
  const now = new Date();
  const day = prefixStringWith0(now.getDate().toString());
  const month = prefixStringWith0((now.getMonth() + 1).toString());
  const fullYear = prefixStringWith0(now.getFullYear().toString());
  const date = `${day}-${month}-${fullYear}`;
  return (
    <div style={{ paddingBottom: '1%' }}>
      <NavBar isPrintedPDF={true} name={data?.userInfo.naam} kvk={data?.userInfo.kvkNummer} date={date} />
      <InformativeBanner
        year={data?.year}
        showPageDescription={false}
        aquiredScore={getTotalScoreByArray(data?.points)}
        isLoading={data?.isLoading}
        maxScore={2500}
        scoreFooter=""
      />
      <ScoreComparingList
        isLoading={data?.isLoading}
        ScoreComparingListItems={data?.items}
        subtitle="Kringloopwijzer Indicatoren"
        title={`Uw score op de 13 indicatoren in ${data?.year}`}
      />
      <div style={{ pageBreakAfter: 'always' }}></div>
      <ScoreComparingList isLoading={data?.isLoading} ScoreComparingListItems={data?.greenItems} subtitle="Groene Indicatoren" />
    </div>
  );
};
