import { atom } from 'jotai';
import moment from 'moment';
import { AtomProps, Item, RewardItem, UserInfo } from 'utilities/types';

export const initLoadingAtom = atom(true);
export const pointAtom = atom<Item[]>([]);
export const infoAtom = atom<Item[]>([]);
export const rewardAtom = atom<Item[]>([]);
export const totalAtom = atom<RewardItem>({ key: '', description: '', value: '' });
export const itemAtom = atom<Item[]>([]);
export const greenItemAtom = atom<Item[]>([]);
export const avgItemAtom = atom<Item[]>([]);
export const avgInfoAtom = atom<Item[]>([]);
export const goalsAtom = atom<Item[]>([]);
export const avgGreenItemAtom = atom<Item[]>([]);
export const userInfoAtom = atom<UserInfo>({});
export const yearsAtom = atom<number[]>([2020, 2021, 2022, 2023]);
export const startYearAtom = atom<number>(2020);
export const selectedYearAtom = atom<number>(moment().year() - 1);
export const descriptionListAtom = atom<AtomProps>({
  first: {
    xs: 1,
  },
  second: {
    xs: 12,
  },
  third: {
    md: 8,
    xs: 7,
  },
  fourth: {
    xs: 3,
  },
});

export const companyInfoListAtom = atom<AtomProps>({
  second: {
    xs: 13,
  },
  third: {
    md: 8,
    xs: 7,
  },
  fourth: {
    xs: 3,
  },
});

export const comparingListAtom = atom<AtomProps>({
  first: {
    xs: 1,
  },
  second: {
    md: 8,
    xs: 8,
  },
  third: {
    md: 15,
    xs: 15,
  },
});
