import { FC } from 'react';
import style from './Total.module.scss';

export interface TotalProps {
  isLoading: boolean;
  total: number;
}
export const Total: FC<TotalProps> = ({ isLoading, total }) => {
  return (
    <div className={style.totalBox}>
      <label className={style.title}>Totaal aantal punten </label>
      <label className={style.points}>{isLoading ? 0 : total}</label>
    </div>
  );
};
