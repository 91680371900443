import { Col } from 'antd';
import { FC } from 'react';
import style from './Score.module.scss';

export interface ScoreProps {
  year: number;
  description: string;
  aquiredScore: number;
  maxScore: number;
  footer: string;
  isLoading: boolean;
  showPageDescription: boolean;
}
export const Score: FC<ScoreProps> = ({ year, description, aquiredScore, maxScore, footer, isLoading, showPageDescription }) => {
  return (
    <Col xs={24} md={showPageDescription ? 10 : 24} className={style.scoreBox}>
      <div>
        <div className={style.description}>
          {description}
          <label className={style.year}>{year}</label>
        </div>
        <div className={style.aquiredScore}>{isLoading ? 0 : aquiredScore}</div>
        <div className={style.maxScore}>op een totaal van {maxScore}</div>
        <div className={style.footer}>{footer}</div>
      </div>
    </Col>
  );
};
