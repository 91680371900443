import { Col, Row } from 'antd';
import { comparingListAtom } from 'atoms';
import classNames from 'classnames';
import { KPIGauge } from 'components/kpi-gauge';
import { useAtom } from 'jotai';
import { FC } from 'react';
import { typeToTypeString } from 'utilities/logic';
import { Item } from 'utilities/types';
import style from './ScoreComparingList.module.scss';
export interface ScoreComparingListItemProps {
  item: Item;
  useGauges: boolean;
}

export const ScoreComparingListItem: FC<ScoreComparingListItemProps> = ({ item, useGauges }) => {
  const [listStyling] = useAtom(comparingListAtom);
  const right = classNames(!useGauges && style.alignright);

  return (
    <Row key={item.key} className={style.ScoreComparingListItem} gutter={20}>
      <Col {...listStyling.first}>{item.order}</Col>
      <Col {...listStyling.second} style={{ whiteSpace: 'pre-line' }}>
        {item.description}
      </Col>
      <Col {...listStyling.third} className={right}>
        {useGauges ? (
          <KPIGauge item={item} type={typeToTypeString(item.type)} />
        ) : (
          <>
            {item.value.toString()}
            {typeToTypeString(item.type)}
          </>
        )}
      </Col>
    </Row>
  );
};
