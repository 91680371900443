import { FC } from 'react';
import { calcPercentage } from 'utilities/logic';
import { Item } from 'utilities/types';
import { Gauge, GaugeProps } from './Gauge';
import { GaugeWeidegang, GaugeWeidegangProps } from './GaugeWeidegang';
export interface KPIGaugeProps {
  item: Item;
  type: string;
}

const getBBMWeidegang = (item: Item, type: string): GaugeWeidegangProps => {
  return {
    type: type,
    steps: [
      {
        value: 'jongvee',
        points: '50',
      },
      {
        value: '1',
        points: '100',
      },
      {
        value: '720',
        points: '150',
      },
      {
        value: '1440',
        points: '200',
      },
    ],
    average: {
      perc: calcPercentage({ value: item.targetScore || 0, x1: 2160, x2: -720 }),
      value: `${item.targetScore}${type}`,
    },
    /*dit is een beetje omslachtig maar weidegang krijgt enkel 50 punten als het jongvee heeft. 
        verder is er geen andere manier om het te laten zien */
    value: { result: item.points === 50 ? 'jongvee' : item.value, x1: 2160, x2: -720 },
  };
};

const getConfig = (item: Item, type: string): GaugeProps | null => {
  switch (item.key) {
    case 'bbm_perc_blijv_grasl':
      return {
        type: type,
        steps: [
          {
            value: 50,
            points: '50',
          },
          {
            value: 60,
            points: '100',
          },
          {
            value: 70,
            points: '150',
          },
          {
            value: 80,
            points: '200',
          },
        ],
        average: {
          perc: calcPercentage({ value: item.targetScore || 0, x1: 90, x2: 40 }),
          value: `${item.targetScore}${type}`,
        },
        value: { result: parseFloat(item.value), x1: 90, x2: 40 },
      };
    case 'bbm_perc_eiw_eig_lnd':
      return {
        type: type,
        steps: [
          {
            value: 55,
            points: '50',
          },
          {
            value: 60,
            points: '100',
          },
          {
            value: 65,
            points: '150',
          },
          {
            value: 70,
            points: '200',
          },
        ],
        average: {
          perc: calcPercentage({ value: item.targetScore || 0, x1: 75, x2: 50 }),
          value: `${item.targetScore}${type}`,
        },
        value: { result: parseFloat(item.value), x1: 75, x2: 50 },
      };
    case 'bbm_gr_bl_doorader':
      return {
        type: type,
        steps: [
          {
            value: 2.5,
            points: '50',
          },
          {
            value: 5,
            points: '100',
          },
          {
            value: 7.5,
            points: '150',
          },
          {
            value: 10,
            points: '200',
          },
        ],
        average: {
          perc: calcPercentage({ value: item.targetScore || 0, x1: 12.5 }),
          value: `${item.targetScore}${type}`,
        },
        value: { result: parseFloat(item.value), x1: 12.5 },
      };
    case 'bbm_kruidrijk_grasl':
      return {
        type: type,
        steps: [
          {
            value: 5,
            points: '50',
          },
          {
            value: 15,
            points: '100',
          },
          {
            value: 30,
            points: '150',
          },
          {
            value: 50,
            points: '200',
          },
        ],
        average: {
          perc: calcPercentage({ value: item.targetScore || 0, x1: 60 }),
          value: `${item.targetScore}${type}`,
        },
        value: { result: parseFloat(item.value), x1: 60 },
      };
    case 'bbm_prc_natbeh':
      return {
        type: type,
        steps: [
          {
            value: 1,
            points: '50',
          },
          {
            value: 5,
            points: '100',
          },
          {
            value: 15,
            points: '150',
          },
          {
            value: 25,
            points: '200',
          },
        ],
        average: {
          perc: calcPercentage({ value: item.targetScore || 0, x1: 30 }),
          value: `${item.targetScore}${type}`,
        },
        value: { result: parseFloat(item.value), x1: 30 },
      };
    case 'bbm_n_bodemoverschot':
      return {
        type: type,
        steps: [
          {
            value: 160,
            points: '50',
          },
          {
            value: 120,
            points: '100',
          },
          {
            value: 90,
            points: '150',
          },
          {
            value: 60,
            points: '200',
          },
        ],
        average: {
          perc: calcPercentage({ value: item.targetScore || 0, x1: 30, x2: 180 }),
          value: `${item.targetScore}${type}`,
        },
        value: { result: parseFloat(item.value), x1: 30, x2: 180 },
      };
    case 'bbm_broeikasgas':
      return {
        type: type,
        steps: [
          {
            value: 1300,
            points: '50',
          },
          {
            value: 1200,
            points: '100',
          },
          {
            value: 1100,
            points: '150',
          },
          {
            value: 1000,
            points: '200',
          },
        ],
        average: {
          perc: calcPercentage({ value: item.targetScore || 0, x1: 900, x2: 1400 }),
          value: `${item.targetScore}${type}`,
        },
        value: { result: parseFloat(item.value), x1: 900, x2: 1400 },
      };
    case 'bbm_ammoniakuitstoot':
      return {
        type: type,
        steps: [
          {
            value: 75,
            points: '50',
          },
          {
            value: 65,
            points: '100',
          },
          {
            value: 55,
            points: '150',
          },
          {
            value: 45,
            points: '200',
          },
        ],
        average: {
          perc: calcPercentage({ value: item.targetScore || 0, x1: 35, x2: 85 }),
          value: `${item.targetScore}${type}`,
        },
        value: { result: parseFloat(item.value), x1: 35, x2: 85 },
      };
    case 'bbm_n_kunstmest':
      return {
        type: type,
        steps: [
          {
            value: 150,
            points: '50',
          },
          {
            value: 100,
            points: '100',
          },
          {
            value: 50,
            points: '150',
          },
          {
            value: 0,
            points: '200',
          },
        ],
        average: {
          perc: calcPercentage({ value: item.targetScore || 0, x1: -50, x2: 200 }),
          value: `${item.targetScore}${type}`,
        },
        value: { result: parseFloat(item.value), x1: -50, x2: 200 },
      };
    case 'bbm_p_bodemoverschot':
      return {
        type: type,
        steps: [
          {
            value: 0,
            points: '50',
          },
          {
            value: -5,
            points: '100',
          },
          {
            value: -10,
            points: '150',
          },
        ],
        average: {
          perc: calcPercentage({ value: item.targetScore || 0, x1: -15, x2: 5 }),
          value: `${item.targetScore}${type}`,
        },
        value: { result: parseFloat(item.value), x1: -15, x2: 5 },
      };
    case 'bbm_eiwit_rantsoen':
      return {
        type: type,
        steps: [
          {
            value: 160,
            points: '50',
          },
          {
            value: 155,
            points: '100',
          },
          {
            value: 150,
            points: '150',
          },
        ],
        average: {
          perc: calcPercentage({ value: item.targetScore || 0, x1: 145, x2: 165 }),
          value: `${item.targetScore}${type}`,
        },
        value: { result: parseFloat(item.value), x1: 145, x2: 165 },
      };
    case 'bbm_gebruik_gbm':
      return {
        type: ' punten',
        steps: [
          {
            value: 50,
            points: '50',
          },
          {
            value: 100,
            points: '100',
          },
          {
            value: 200,
            points: '200',
          },
        ],
        average: {
          perc: calcPercentage({ value: item.targetScore || 0, x1: 250, x2: 0 }),
          value: `${item.targetScore}${type}`,
        },
        value: { result: parseFloat(item.value), x1: 250, x2: 0 },
      };
    case 'legend':
      return {
        type: ' punten',
        steps: [
          {
            value: 20,
            points: '50',
          },
          {
            value: 40,
            points: '100',
          },
          {
            value: 60,
            points: '150',
          },
          {
            value: 80,
            points: '200',
          },
        ],
        average: {
          perc: calcPercentage({ value: item.targetScore || 0 }),
          value: `${item.targetScore}${type}`,
        },
        value: { result: parseFloat(item.value) },
      };
    default:
      return null;
  }
};

export const KPIGauge: FC<KPIGaugeProps> = ({ item, type }) => {
  const config = getConfig(item, type);
  if (item.key === 'bbm_weidegang') {
    const config = getBBMWeidegang(item, type);
    return <GaugeWeidegang {...config} />;
  }
  return config ? <Gauge {...config} /> : <></>;
};
