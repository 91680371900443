import { Col } from 'antd';
import { FC } from 'react';
import style from './PageDescription.module.scss';

export interface PageDescriptionProps {
  title: string;
  description: string;
}
export const PageDescription: FC<PageDescriptionProps> = ({ title, description }) => {
  return (
    <Col xs={24} sm={24} md={14} className={style.descriptionBox}>
      <div>
        <h1 className={style.title}>{title}</h1>
        <p>{description}</p>
      </div>
    </Col>
  );
};
