import { Spin } from 'antd';
import {
  rewardAtom,
  greenItemAtom,
  initLoadingAtom,
  itemAtom,
  pointAtom,
  totalAtom,
  infoAtom,
  selectedYearAtom,
  goalsAtom,
  userInfoAtom,
  startYearAtom,
} from 'atoms';
import { FooterBanner, InformativeBanner, NavBar, ScoreDescriptionList, Total } from 'components';
import { useAtom } from 'jotai';
import { FC } from 'react';
import { getTotalScoreByArray } from 'utilities/logic';

export const Home: FC = () => {
  const [points] = useAtom(pointAtom);
  const [reward] = useAtom(rewardAtom);
  const [total] = useAtom(totalAtom);
  const [items] = useAtom(itemAtom);
  const [info] = useAtom(infoAtom);
  const [greenItems] = useAtom(greenItemAtom);
  const [goals] = useAtom(goalsAtom);
  const [isLoading] = useAtom(initLoadingAtom);
  const [year] = useAtom(selectedYearAtom);
  const [userInfo] = useAtom(userInfoAtom);
  const [startYear] = useAtom(startYearAtom);
  const totalScore = getTotalScoreByArray(points);
  return (
    <div style={{ paddingBottom: '1%' }}>
      <Spin spinning={isLoading}>
        <NavBar />
        <InformativeBanner
          pageDescriptionTitle={`Welkom ${userInfo.naam}`}
          aquiredScore={totalScore}
          isLoading={isLoading}
          year={year}
          maxScore={2500}
        />
        <ScoreDescriptionList
          isCompanyInfo={true}
          isLoading={isLoading}
          scoreDescriptionListItems={info}
          subtitle="Algemene bedrijfsinformatie"
        />
        <ScoreDescriptionList
          isLoading={isLoading}
          scoreDescriptionListItems={items}
          subtitle="Kringloopwijzer Indicatoren"
          title="Hoe is de score opgebouwd"
        />
        <ScoreDescriptionList isLoading={isLoading} scoreDescriptionListItems={greenItems} subtitle="Groene Indicatoren" />
        <Total isLoading={isLoading} total={totalScore} />
        <FooterBanner
          year={year}
          startYear={startYear}
          rewards={reward}
          nextGoalEmpty={false}
          total={total}
          isLoading={isLoading}
          score={totalScore}
          goals={goals}
        />
      </Spin>
    </div>
  );
};
