import { Skeleton } from 'antd';
import { useAtom } from 'jotai';
import { FC, useEffect } from 'react';
import { api } from 'services/api.axios';
import { isAuthenticatedAtom, isAuthInitializingAtom, keycloakInstanceAtom } from './atoms';

// UPDATE BEARER TOKEN FUNCTION
const updateToken = (token: string | undefined) => (api.defaults.headers.common['Authorization'] = `Bearer ${token}`);

export const AuthComponent: FC = ({ children }) => {
  const [isInitializing, setInitializing] = useAtom(isAuthInitializingAtom);
  const [keycloak] = useAtom(keycloakInstanceAtom);
  const [, setAuthenticated] = useAtom(isAuthenticatedAtom);

  useEffect(() => {
    keycloak
      .init({
        onLoad: 'login-required',
        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      })
      .then((auth: boolean) => {
        setAuthenticated(auth);
        setInitializing(false);
      })
      .catch((e) => console.log('Keycloak onInit error', e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  keycloak.onTokenExpired = () => {
    keycloak
      .updateToken(60)
      .then((refreshed) => {
        if (refreshed) console.log('refreshed token');
        else console.log('not refreshed token');
      })
      .catch(() => console.error('Failed to refresh token ' + new Date()));
  };

  keycloak.onAuthSuccess = () => updateToken(keycloak.token);
  keycloak.onAuthRefreshSuccess = () => updateToken(keycloak.token);
  keycloak.onAuthError = (error) => console.error('Keycloak onAuthError:', error);
  keycloak.onAuthRefreshError = () => console.error('Keycloak onAuthRefreshError');

  return (
    <>
      <Skeleton active loading={isInitializing}>
        {children}
      </Skeleton>
    </>
  );
};
