import { Button } from 'antd';
import { greenItemAtom, initLoadingAtom, itemAtom, pointAtom, selectedYearAtom, userInfoAtom } from 'atoms';
import { useAtom } from 'jotai';
import { FC, useState } from 'react';
import { downloadPdf, prefixStringWith0 } from 'utilities/logic';
import style from './ReportGenerator.module.scss';

export interface ReportGeneratorProps {}
export const ReportGenerator: FC<ReportGeneratorProps> = () => {
  const [points] = useAtom(pointAtom);
  const [items] = useAtom(itemAtom);
  const [greenItems] = useAtom(greenItemAtom);
  const [year] = useAtom(selectedYearAtom);
  const [isLoading] = useAtom(initLoadingAtom);
  const [userInfo] = useAtom(userInfoAtom);
  const [isExporting, setExport] = useState(false);
  const GenerateReport = async () => {
    setExport(true);
    const printData = {
      year,
      points,
      items,
      greenItems,
      isLoading,
      userInfo,
    };

    const now = new Date();
    const day = prefixStringWith0(now.getDate().toString());
    const month = prefixStringWith0((now.getMonth() + 1).toString());
    const fullYear = prefixStringWith0(now.getFullYear().toString());
    const hours = prefixStringWith0(now.getHours().toString());
    const minutes = prefixStringWith0(now.getMinutes().toString());
    const date = `${day}${month}${fullYear}${hours}${minutes}`;

    await downloadPdf(`http://frontend/export/compare`, printData, `Biodiversiteits_Monitor_rapport_${year}_${date}.pdf`);
    setExport(false);
  };
  return (
    <div className={style.ReportGeneratorBox}>
      <Button loading={isExporting} onClick={GenerateReport} className={style.btn}>
        Rapport afdrukken
      </Button>
    </div>
  );
};
