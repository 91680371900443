import { Row, Space, Tooltip } from 'antd';
import { FC } from 'react';
import { calcPercentage } from 'utilities/logic';
import { Average, Step, Value } from 'utilities/types';
import style from './KPIGauge.module.scss';

export interface GaugeProps {
  value: Value;
  steps: Step[];
  average: Average;
  type: string;
}
export const Gauge: FC<GaugeProps> = ({ average, value, steps, type }) => {
  const width = calcPercentage({
    value: value.result,
    x1: value.x1,
    x2: value.x2,
  });
  return (
    <div className={style.padding}>
      <Space style={{ width: '100%' }} direction={'vertical'} size={30}>
        <Row className={style.axisTitle}>Punten</Row>
        <Row className={style.progress}>
          <div className={style.barsteps}>
            {steps.map((step) => (
              <div
                className={style.barStep}
                style={{ left: `${calcPercentage({ value: step.value, x1: value.x1, x2: value.x2 })}%` }}
              >
                <div className={style.labelBottom}>{step.value}</div>
                <div className={style.labelLine}></div>
                <div className={style.labelTop}>{step.points}</div>
              </div>
            ))}
          </div>
          {average && (
            <div className={style.barStep} style={{ left: `${average.perc || 0}%` }}>
              <Tooltip overlay={() => <div>Gemiddelde {average.value}</div>}>
                <div className={style.avgLine}></div>
              </Tooltip>
            </div>
          )}
          <div
            className={style.progressBar}
            style={{
              width: `${width}%`,
            }}
          ></div>
          {width === 0 ? (
            <Tooltip overlay={() => <div>Uw waarde valt te ver buiten de score</div>}>
              <div
                className={style.text}
                style={{
                  backgroundColor: '#DF3C3C',
                  padding: '0 5px',
                  transform: 'translateY(-50%)',
                }}
              >
                {value.result}
              </div>
            </Tooltip>
          ) : (
            <div
              className={style.text}
              style={{
                left: `${width / 2}%`,
              }}
            >
              {value.result}
            </div>
          )}
        </Row>
        <Row className={style.axisTitle}>Resultaat</Row>
      </Space>
    </div>
  );
};
