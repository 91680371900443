import { Col, Row } from 'antd';
import { companyInfoListAtom, descriptionListAtom } from 'atoms';
import { useAtom } from 'jotai';
import { FC } from 'react';
import { Item } from 'utilities/types';
import style from './ScoreDescriptionList.module.scss';
import { ScoreDescriptionListItem } from './ScoreDescriptionListItem';

export interface ScoreDescriptionListProps {
  title?: string;
  subtitle?: string;
  scoreDescriptionListItems?: Item[];
  isLoading: boolean;
  isCompanyInfo?: boolean;
}

export const ScoreDescriptionList: FC<ScoreDescriptionListProps> = ({
  title = '',
  subtitle,
  scoreDescriptionListItems,
  isCompanyInfo = false,
}) => {
  const [listStyling] = useAtom(descriptionListAtom);
  const [companyList] = useAtom(companyInfoListAtom);
  let styling;
  isCompanyInfo ? (styling = companyList) : (styling = listStyling);
  return (
    <div className={style.list}>
      <h1>{title}</h1>
      <h2>{subtitle}</h2>
      <Row className={style.table}>
        {!isCompanyInfo && (
          <Col {...styling.first} className={style.alignleft}>
            #
          </Col>
        )}
        <Col {...styling.second} className={style.alignleft}>
          Indicatoren
        </Col>
        <Col {...styling.third} className={style.alignright}>
          Resultaat
        </Col>
        <Col {...styling.fourth} className={style.alignright}>
          Punten
        </Col>
      </Row>
      <div className={style.ScoreDescriptionList}>
        {scoreDescriptionListItems && scoreDescriptionListItems.length > 0
          ? scoreDescriptionListItems.map((item) => <ScoreDescriptionListItem key={item.key} isCompanyInfo={isCompanyInfo} item={item} />)
          : 'Geen resultaten'}
      </div>
    </div>
  );
};
