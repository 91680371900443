import { atom } from 'jotai';
import Keycloak from 'keycloak-js';

const keycloak = Keycloak({
  clientId: (window as any).AUTH_CLIENT_ID,
  realm: (window as any).AUTH_REALM,
  url: (window as any).AUTH_URL + '/auth',
});

export const keycloakInstanceAtom = atom(keycloak);

export const isAuthenticatedAtom = atom(false);
export const isAuthInitializingAtom = atom(true);
