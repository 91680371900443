import { Spin } from 'antd';
import { greenItemAtom, initLoadingAtom, itemAtom, pointAtom, selectedYearAtom } from 'atoms';
import { InformativeBanner, NavBar } from 'components';
import { ReportGenerator } from 'components/report-generator';
import { ScoreComparingList } from 'components/score-comparing-list';
import { useAtom } from 'jotai';

import { FC } from 'react';
import { getTotalScoreByArray } from 'utilities/logic';

export interface CompareProps {}

export const Compare: FC<CompareProps> = () => {
  const [points] = useAtom(pointAtom);
  const [items] = useAtom(itemAtom);
  const [greenItems] = useAtom(greenItemAtom);
  const [isLoading] = useAtom(initLoadingAtom);
  const totalScore = getTotalScoreByArray(points);
  const [year] = useAtom(selectedYearAtom);

  return (
    <div style={{ paddingBottom: '1%' }}>
      <Spin spinning={isLoading}>
        <NavBar />
        <InformativeBanner
          year={year}
          pageDescriptionTitle="Vergelijk uw score"
          pageDescription="Om u inzicht te geven wat u nog kunt doen om meer punten te behalen, 
          is in onderstaande diagrammen te zien hoe uw scores zijn opgebouwd. 
          Tevens is per indicator weergegeven met welke score u meer punten kunt behalen. Ook is te zien wat de gemiddelde score van alle deelnemers is. "
          aquiredScore={totalScore}
          isLoading={isLoading}
          maxScore={2500}
          scoreFooter=""
        />
        <ScoreComparingList
          isLoading={isLoading}
          ScoreComparingListItems={[
            {
              order: 0,
              description: `De blauwe balk geeft aan wat uw resultaat is. 
            Als u met uw muis over het lichtblauwe streepje gaat ziet u het gemiddelde van alle deelnemers`,
              key: 'legend',
              isEditable: false,
              type: 'punten',
              value: '50',
              targetScore: 65,
            },
          ]}
          subtitle="Legenda"
        />
        <ScoreComparingList
          isLoading={isLoading}
          ScoreComparingListItems={items}
          subtitle="Kringloopwijzer Indicatoren"
          title={`Uw score op de 13 indicatoren in ${year}`}
        />
        <ScoreComparingList isLoading={isLoading} ScoreComparingListItems={greenItems} subtitle="Groene Indicatoren" />
        <ReportGenerator />
      </Spin>
    </div>
  );
};
