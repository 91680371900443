import { Row, Space, Tooltip } from 'antd';
import { FC } from 'react';
import { calcPercentage } from 'utilities/logic';
import { Average, WeidegangStep, WeidegangValue } from 'utilities/types';
import style from './KPIGauge.module.scss';

export interface GaugeWeidegangProps {
  value: WeidegangValue;
  steps: WeidegangStep[];
  average: Average;
  type: string;
}
export const GaugeWeidegang: FC<GaugeWeidegangProps> = ({ average, value, steps, type }) => {
  let result = value.result;
  if (value.result === 'jongvee') result = '-395';
  if (value.result === '1') {
    value.result = 'deelweidegang';
  }
  if(value.result === '0'){
    result= '-720'
  }
  const width = calcPercentage({
    value: parseFloat(result),
    x1: value.x1,
    x2: value.x2,
  });
  return (
    <div className={style.padding}>
      <Space style={{ width: '100%' }} direction={'vertical'} size={30}>
        <Row className={style.axisTitle}>Punten</Row>
        <Row className={style.progress}>
          <div className={style.barsteps}>
            {steps.map((step) => {
              if (step.value === 'jongvee') {
                return (
                  <div
                    className={style.barStep}
                    style={{ left: `${calcPercentage({ value: -395, x1: value.x1, x2: value.x2 })}%` }}
                  >
                    <div className={style.labelBottom}>{step.value}</div>
                    <div className={style.labelLine}></div>
                    <div className={style.labelTop}>{step.points}</div>
                  </div>
                );
              } else if (step.value === '1') {
                return (
                  <div
                    className={style.barStep}
                    style={{ left: `${calcPercentage({ value: parseFloat(step.value), x1: value.x1, x2: value.x2 })}%` }}
                  >
                    <div className={style.labelBottom}>deelweidegang</div>
                    <div className={style.labelLine}></div>
                    <div className={style.labelTop}>{step.points}</div>
                  </div>
                );
              } else {
                return (
                  <div
                    className={style.barStep}
                    style={{ left: `${calcPercentage({ value: parseFloat(step.value), x1: value.x1, x2: value.x2 })}%` }}
                  >
                    <div className={style.labelBottom}>{step.value}</div>
                    <div className={style.labelLine}></div>
                    <div className={style.labelTop}>{step.points}</div>
                  </div>
                );
              }
            })}
          </div>
          {average && (
            <div className={style.barStep} style={{ left: `${average.perc || 0}%` }}>
              <Tooltip overlay={() => <div>Gemiddelde {average.value}</div>}>
                <div className={style.avgLine}></div>
              </Tooltip>
            </div>
          )}
          <div
            className={style.progressBar}
            style={{
              width: `${width}%`,
            }}
          ></div>
          {width === 0 ? (
            <Tooltip overlay={() => <div>Uw waarde valt te ver buiten de score</div>}>
              <div
                className={style.text}
                style={{
                  backgroundColor: '#DF3C3C',
                  padding: '0 5px',
                  transform: 'translateY(-50%)',
                }}
              >
                {value.result}
              </div>
            </Tooltip>
          ) : (
            <div
              className={style.text}
              style={{
                left: `${width / 2}%`,
              }}
            >
              {value.result}
            </div>
          )}
        </Row>
        <Row className={style.axisTitle}>Resultaat</Row>
      </Space>
    </div>
  );
};
