import { api } from 'services/api.axios';
import { Avg, Item, UserInfo } from 'utilities/types';

interface KPIValueItemResponse {
  group: string;
  groupDescription: string;
  kpiValues: Item[];
}

interface KPIValueAvgResponse {
  group: string;
  groupDescription: string;
  kpiValues: Avg[];
}

export const getKpiValuesByGroup = async (group: string, year: number) => {
  const config = {
    params: {
      periodUnit: 'J',
      periodValue: year,
      periodNumber: 1,
    },
  };
  const res = (await api.get(`KpiValues/group/${group}`, config)) as KPIValueItemResponse;
  if (res) return res;
  return {
    group: 'Error',
    groupDescription: 'Fout tijdens ophalen van gegevens',
    kpiValues: [],
  };
};
export const getUserInfo = async () => {
  const res = (await api.get(`relation`)) as UserInfo;
  if (res) return res;
  return {};
};

export const getAverageBenchmarks = async (group: string, year: number) => {
  const config = {
    params: {
      databaseSchema: 'dbo',
      selectionType: 'ALLES',
      group: group,
      periodUnit: 'J',
      periodValue: year,
      periodNumber: 1,
    },
  };
  const res = (await api.get(`Benchmarks/averages`, config)) as KPIValueAvgResponse;
  if (res) return res;
  return {
    group: 'Error',
    groupDescription: 'Fout tijdens ophalen van gegevens',
    kpiValues: [],
  };
};
