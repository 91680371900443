import { Col, Row } from 'antd';
import { companyInfoListAtom, descriptionListAtom } from 'atoms';
import { useAtom } from 'jotai';
import { FC } from 'react';
import { typeToTypeString } from 'utilities/logic';
import { Item } from 'utilities/types';
import style from './ScoreDescriptionList.module.scss';

export interface ScoreDescriptionListItemProps {
  item: Item;
  isCompanyInfo: boolean;
}

export const ScoreDescriptionListItem: FC<ScoreDescriptionListItemProps> = ({ item, isCompanyInfo }) => {
  const [listStyling] = useAtom(descriptionListAtom);
  const [companyList] = useAtom(companyInfoListAtom);
  let styling;
  isCompanyInfo ? (styling = companyList) : (styling = listStyling);
  return (
    <Row className={style.ScoreDescriptionListItem} gutter={20}>
      {!isCompanyInfo && (
        <Col {...styling.first} className={style.alignleft}>
          {item.order}
        </Col>
      )}
      <Col {...styling.second} className={style.alignleft}>
        {item.description}
      </Col>
      <Col {...styling.third} className={style.alignright}>
        {/*dit is een beetje omslachtig maar weidegang krijgt enkel 50 punten als het jongvee heeft. 
        verder is er geen andere manier om het te laten zien */}
        {item.key === 'bbm_weidegang' && item.points === 50 ? (
          'jongvee'
        ) : (
          <>
            {item.value.toString()}
            {typeToTypeString(item.type)}
          </>
        )}
      </Col>
      <Col {...styling.fourth} className={style.alignright}>
        {item.points ?? 'NVT'}
      </Col>
    </Row>
  );
};
