import { Col, Row } from 'antd';
import { FC } from 'react';
import style from './RewardTable.module.scss';

export interface RewardTableProps {}
const styling = {
  first: {
    xs: 8,
  },
  second: {
    xs: 16,
  },
};

export const RewardTable: FC<RewardTableProps> = () => {
  return (
    <div>
      <Row gutter={20} className={style.table}>
        <Col {...styling.first} className={style.center}>
          Score
        </Col>
        <Col {...styling.second}>Beloning</Col>
      </Row>
      <Row gutter={20} className={style.table}>
        <Col {...styling.first} className={style.center}>
          300-1499
        </Col>
        <Col {...styling.second}>€ 1 per punt</Col>
      </Row>
      <Row gutter={20} className={style.table}>
        <Col {...styling.first} className={style.center}>
          1500-1999
        </Col>
        <Col {...styling.second}>€ 1 per punt + bonus € 2.000</Col>
      </Row>
      <Row gutter={20} className={style.table}>
        <Col {...styling.first} className={style.center}>
          2000-2500
        </Col>
        <Col {...styling.second}>€ 1 per punt + bonus € 2.500</Col>
      </Row>
    </div>
  );
};
