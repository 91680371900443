import { DownOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Menu, Radio, Row, Space } from 'antd';
import { selectedYearAtom, startYearAtom, yearsAtom } from 'atoms';
import classNames from 'classnames';
import { keycloakInstanceAtom } from 'components/authentication/atoms';
import { useAtom } from 'jotai';
import { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import style from './NavBar.module.scss';

export interface NavBarProps {
  isPrintedPDF?: boolean;
  name?: string;
  kvk?: string;
  date?: string;
}
export const NavBar: FC<NavBarProps> = ({ isPrintedPDF = false, name = '', kvk = '', date = '' }) => {
  const history = useHistory();
  const [kc] = useAtom(keycloakInstanceAtom);
  const [years] = useAtom(yearsAtom);
  const [startYear] = useAtom(startYearAtom);
  const [selectedYear, setSelectedYear] = useAtom(selectedYearAtom);
  const loc = useLocation();
  const monitorClasses = classNames(style.spacer, style.report, loc.pathname === '/' && style.selected);
  const compareClasses = classNames(style.spacer, style.goals, loc.pathname === '/vergelijk' && style.selected);
  const navClasses = classNames(style.navBar, isPrintedPDF ? style.padding15 : style.padding30);
  const selectYear = (year) => {
    setSelectedYear(parseInt(year.key));
  };
  const menu = () => {
    let menuItems: JSX.Element[] = [];
    for (let i = years.sort((a, b) => b - a)[0]; i >= startYear; i--) {
      menuItems.push(<Menu.Item key={i}>{i}</Menu.Item>);
    }

    return <Menu onClick={selectYear}>{menuItems}</Menu>;
  };
  return (
    <Row gutter={20} className={navClasses} justify="space-between">
      <Col xs={24} sm={10} md={5} style={{ textAlign: 'center' }}>
        <label className={style.logoEnd}>Brabantse </label>
        <label className={style.logoStart}>Biodiversiteits</label>
        <label className={style.logoEnd}>monitor </label>
        <label className={style.logoEnd}>Melkveehouderij</label>
      </Col>
      <Col>
        {isPrintedPDF ? (
          <Space direction="vertical" size={1}>
            <label>{name}</label>
            <label>kvk: {kvk}</label>
            <label>datum: {date}</label>
          </Space>
        ) : (
          <Space wrap direction="horizontal" size={30}>
            <Dropdown overlay={menu}>
              <Button>
                {selectedYear} <DownOutlined />
              </Button>
            </Dropdown>
            <Radio.Group defaultValue={loc.pathname === '/' ? 0 : 1} buttonStyle="solid">
              <Radio.Button value={0} className={monitorClasses} onClick={() => history.push('/')}>
                Monitor
              </Radio.Button>
              <Radio.Button value={1} className={compareClasses} onClick={() => history.push('/vergelijk')}>
                Vergelijk
              </Radio.Button>
            </Radio.Group>

            <Button className={style.logOutBtn} onClick={() => kc.logout()}>
              Uitloggen
            </Button>
          </Space>
        )}
      </Col>
    </Row>
  );
};
