import { Col, Row } from 'antd';
import { comparingListAtom } from 'atoms';
import classNames from 'classnames';
import { useAtom } from 'jotai';
import { FC } from 'react';
import { Item } from 'utilities/types';
import style from './ScoreComparingList.module.scss';
import { ScoreComparingListItem } from './ScoreComparingListItem';

export interface ScoreComparingListProps {
  title?: string;
  subtitle?: string;
  ScoreComparingListItems?: Item[];
  isLoading: boolean;
  useGauges?: boolean;
}

export const ScoreComparingList: FC<ScoreComparingListProps> = ({
  title = '',
  subtitle = '',
  ScoreComparingListItems,
  useGauges = true,
}) => {
  const [listStyling] = useAtom(comparingListAtom);
  const right = classNames(!useGauges && style.alignright);
  return (
    <div className={style.list}>
      <h1>{title}</h1>
      <h2>{subtitle}</h2>
      <Row gutter={20} className={style.table}>
        <Col {...listStyling.first}>#</Col>
        <Col {...listStyling.second}>Indicatoren</Col>
        <Col {...listStyling.third} className={right}>
          Resultaat
        </Col>
      </Row>
      <div className={style.ScoreComparingList}>
        {ScoreComparingListItems && ScoreComparingListItems.length > 0
          ? ScoreComparingListItems.map((item) => <ScoreComparingListItem useGauges={useGauges} key={item.key} item={item} />)
          : 'Geen resultaten'}
      </div>
    </div>
  );
};
