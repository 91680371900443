import { Skeleton } from 'antd';
import { useAtom } from 'jotai';
import { FC, useEffect } from 'react';
import { getAverageBenchmarks, getKpiValuesByGroup, getUserInfo } from 'services/dataservice';
import { getSubrewards, getTotal, mapItems } from 'utilities/logic';
import {
  goalsAtom,
  greenItemAtom,
  infoAtom,
  initLoadingAtom,
  itemAtom,
  pointAtom,
  rewardAtom,
  selectedYearAtom,
  startYearAtom,
  totalAtom,
  userInfoAtom,
} from '../atoms/atoms';

export const InitWrapper: FC = ({ children }) => {
  const [isLoading, setLoading] = useAtom(initLoadingAtom);
  const [, setPoints] = useAtom(pointAtom);
  const [, setReward] = useAtom(rewardAtom);
  const [, setTotal] = useAtom(totalAtom);
  const [, setItems] = useAtom(itemAtom);
  const [, setGreenItems] = useAtom(greenItemAtom);
  const [, setInfo] = useAtom(infoAtom);
  const [, setUserInfo] = useAtom(userInfoAtom);
  const [, setGoals] = useAtom(goalsAtom);
  const [, setStartYear] = useAtom(startYearAtom);
  const [selectedYear, setSelectedYear] = useAtom(selectedYearAtom);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      const indicators = await getKpiValuesByGroup('BBM_INDICATOREN', selectedYear);
      const greenIndicators = await getKpiValuesByGroup('BBM_INDICATOREN2', selectedYear);
      const points = await getKpiValuesByGroup('BBM_PUNTEN', selectedYear);
      const reward = await getKpiValuesByGroup('BBM_BELONING', selectedYear);
      const info = await getKpiValuesByGroup('BBM_ALG_GEG', selectedYear);
      const avgIndicators = await getAverageBenchmarks('BBM_INDICATOREN', selectedYear);
      const avgGreenIndicators = await getAverageBenchmarks('BBM_INDICATOREN2', selectedYear);
      const avgInfo = await getAverageBenchmarks('BBM_ALG_GEG', selectedYear);
      const goals = await getKpiValuesByGroup('BBM_DOELEN', selectedYear);
      const userInfo = await getUserInfo();

      setPoints(points.kpiValues);
      setReward(getSubrewards(reward.kpiValues));
      setTotal(getTotal(reward.kpiValues));
      setItems(mapItems(indicators.kpiValues, points.kpiValues, avgIndicators.kpiValues));
      setGreenItems(mapItems(greenIndicators.kpiValues, points.kpiValues, avgGreenIndicators.kpiValues));
      setInfo(mapItems(info.kpiValues, [], avgInfo.kpiValues));
      setUserInfo(userInfo);
      setGoals(goals.kpiValues);
      const startYear = info.kpiValues.find((kpi) => kpi.key === 'bbm_startjaar')?.value;
      if (startYear) setStartYear(parseFloat(startYear));
    };

    loadData().finally(() => {
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear]);

  return (
    <Skeleton active loading={isLoading}>
      {children}
    </Skeleton>
  );
};
