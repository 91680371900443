import { Button, Col } from 'antd';
import {
  rewardAtom,
  greenItemAtom,
  initLoadingAtom,
  itemAtom,
  pointAtom,
  totalAtom,
  infoAtom,
  selectedYearAtom,
  goalsAtom,
  userInfoAtom,
  startYearAtom,
} from 'atoms';
import { useAtom } from 'jotai';
import { FC, useState } from 'react';
import { downloadPdf, getTotalScoreByArray, prefixStringWith0 } from 'utilities/logic';
import { Item } from 'utilities/types';
import style from './NextGoal.module.scss';

export interface NextGoalProps {
  title: string;
  nextGoalEmpty: boolean;
  text: string;
  goalList: Item[];
}

export const NextGoal: FC<NextGoalProps> = ({ title, text, nextGoalEmpty, goalList }) => {
  const [points] = useAtom(pointAtom);
  const [reward] = useAtom(rewardAtom);
  const [total] = useAtom(totalAtom);
  const [items] = useAtom(itemAtom);
  const [info] = useAtom(infoAtom);
  const [greenItems] = useAtom(greenItemAtom);
  const [goals] = useAtom(goalsAtom);
  const [year] = useAtom(selectedYearAtom);
  const [startYear] = useAtom(startYearAtom);
  const [isLoading] = useAtom(initLoadingAtom);
  const [userInfo] = useAtom(userInfoAtom);
  const totalScore = getTotalScoreByArray(points);
  const [isExporting, setExport] = useState(false);

  const GenerateReport = async () => {
    setExport(true);
    const filteredInfo = info.filter((item) => item.key !== 'bbm_startjaar');
    const printData = {
      reward,
      total,
      items,
      filteredInfo,
      greenItems,
      isLoading,
      totalScore,
      year,
      goals,
      userInfo,
      startYear,
    };

    const now = new Date();
    const day = prefixStringWith0(now.getDate().toString());
    const month = prefixStringWith0((now.getMonth() + 1).toString());
    const fullYear = prefixStringWith0(now.getFullYear().toString());
    const hours = prefixStringWith0(now.getHours().toString());
    const minutes = prefixStringWith0(now.getMinutes().toString());
    const date = `${day}${month}${fullYear}${hours}${minutes}`;

    await downloadPdf(`http://frontend/export/results`, printData, `Biodiversiteits_Monitor_rapport_${year}_${date}.pdf`);
    setExport(false);
  };
  return (
    <Col xs={24} md={12} className={style.rewardBox}>
      <div>
        <h1>{title}</h1>
        <p>{text}</p>
        <p>Uw prognose:</p>
        {goalList.map((goal) => (
          <div className={style.text} key={goal.key}>
            {goal.description.slice(-4)}:{'   '} {goal.value} punten
          </div>
        ))}
        {!nextGoalEmpty && (
          <Button loading={isExporting} onClick={GenerateReport} className={style.btn}>
            Rapport afdrukken
          </Button>
        )}
      </div>
    </Col>
  );
};
