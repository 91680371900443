import { Row } from 'antd';
import { NextGoal, Reward } from 'components';
import { FC } from 'react';
import { Item, RewardItem } from 'utilities/types';
import style from './FooterBanner.module.scss';

export interface FooterBannerProps {
  rewardTitle?: string;
  score: number;
  rewards: RewardItem[];
  goals: Item[];
  total: RewardItem;
  nextGoalTitle?: string;
  nextGoalText?: string;
  startYear: number;
  year?: number;
  isLoading: boolean;
  nextGoalEmpty: boolean;
}

export const FooterBanner: FC<FooterBannerProps> = ({
  rewardTitle = 'Beloning ',
  score,
  rewards,
  nextGoalText = `Op uw aanvraagformulier heeft u een prognose opgegeven hoeveel punten u in 2021, 2022 en 2023 verwacht te bereiken. 
  Deze heeft u ingevuld op basis van de maatregelen die u wilt nemen.`,
  nextGoalTitle = 'De door u aangegeven doelen',
  nextGoalEmpty,
  startYear,
  isLoading,
  year = 0,
  total,
  goals,
}) => {
  if (year === startYear) {
    rewardTitle = 'Nulmeting ';
  }
  return (
    <Row className={style.margin} gutter={20}>
      <Reward score={score} title={`${rewardTitle}`} rewards={rewards} isLoading={isLoading} total={total} />
      <NextGoal nextGoalEmpty={nextGoalEmpty} title={nextGoalTitle} text={nextGoalText} goalList={goals} />
    </Row>
  );
};
