import { Col, Row } from 'antd';
import { RewardTable } from 'components/reward-list';
import { FC } from 'react';
import { RewardItem } from 'utilities/types';
import style from './Reward.module.scss';

export interface RewardProps {
  title: string;
  score: number;
  rewards?: RewardItem[];
  total: RewardItem;
  isLoading: boolean;
}

const formatter = new Intl.NumberFormat('nl-NL', {
  style: 'currency',
  currency: 'EUR',
});
export const Reward: FC<RewardProps> = ({ title, score, rewards, isLoading, total }) => {
  let bonus: RewardItem = { key: '', description: '', value: '0' };
  const bbmbonus = rewards?.find((reward) => reward.key === 'bbm_bonus_euro');
  bonus = bbmbonus || { key: '', description: '', value: '0' };

  return (
    <Col xs={24} md={12} className={style.rewardBox}>
      <div>
        <h1 className={style.title}>{title}</h1>
        <p>De beloning wordt als volgt berekend:</p>
        <RewardTable />
        <Row className={style.total}>
          <Col xs={20}>Uw gerealiseerde totaal puntenscore is:</Col>
          <Col xs={4} className={`${style.right} ${style.secondary}`}>
            {isLoading ? 0 : score.toString()}
          </Col>
        </Row>
        <Row className={style.total}>
          <Col md={10} xs={12}>
            Bij deze score hoort:
          </Col>
          <Col md={14} xs={12} className={`${style.right} ${style.secondary}`}>
            {formatter.format(parseFloat(bonus.value))} bonus
          </Col>
        </Row>
        <Row>
          <Col xs={14}>Uw berekende beloning is:</Col>
          <Col xs={10} className={`${style.right} ${style.secondary}`}>
            {formatter.format(score)} + {formatter.format(parseFloat(bonus.value))}
          </Col>
        </Row>
        <Row className={style.total}>
          <Col xs={5}>Totaal:</Col>
          <Col xs={19} className={style.bonus}>
            {formatter.format(parseFloat(total.value))}*
          </Col>
        </Row>
        <p className={style.small}>
          * In de jaren 2022, 2023 en 2024 ontvangt u een beloning voor uw prestaties in het voorgaande jaar. De berekende
          beloning wordt uitgekeerd met een maximum dat niet hoger is dan op basis van het door u gestelde doel voor het
          betreffende jaar.
        </p>
      </div>
    </Col>
  );
};
