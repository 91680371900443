import React, { Suspense } from 'react';
import './styles.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Compare, Home, Result } from 'pages';
import { ConfigProvider } from 'antd';
import { ContainerWrapper, ErrorBoundary } from 'components';
import nlNL from 'antd/es/locale/nl_NL';
import { Provider } from 'jotai';
import { AuthComponent } from 'components/authentication/authComponent';
import { InitWrapper } from './InitWrapper';
import { ComparePrint } from 'pages/compare-print';

export const App = () => {
  return (
    <Suspense fallback={<p>loading</p>}>
      <Provider>
        <Router>
          <ConfigProvider locale={nlNL}>
            <ErrorBoundary>
              <ContainerWrapper>
                <Switch>
                  <Route path="/export">
                    <UnprotectedApp />
                  </Route>
                  <Route path="/">
                    <ProtectedApp />
                  </Route>
                </Switch>
              </ContainerWrapper>
            </ErrorBoundary>
          </ConfigProvider>
        </Router>
      </Provider>
    </Suspense>
  );
};

export const ProtectedApp = () => {
  return (
    <AuthComponent>
      <InitWrapper>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/vergelijk">
            <Compare />
          </Route>
        </Switch>
      </InitWrapper>
    </AuthComponent>
  );
};
export const UnprotectedApp = () => {
  return (
    <Switch>
      <Route exact path="/export/results">
        <Result />
      </Route>
      <Route exact path="/export/compare">
        <ComparePrint />
      </Route>
    </Switch>
  );
};
