import { notification } from 'antd';
import axios, { AxiosRequestConfig } from 'axios';

// @ts-ignore
const API_URL = window.API_URL;

const axiosConfig: AxiosRequestConfig = {
  baseURL: API_URL,
  timeout: 3000,
};

export const api = axios.create(axiosConfig);

api.interceptors.response.use(
  (r) => r.data,
  (e) => {
    notification.error({message:"Er is geen data beschikbaar"});
    // Promise.reject(e)
  },
);
