import { Avg, calcPercentageType, Item, RewardItem } from './types';

export const getTotalScoreByArray = (array: Item[]): number => {
  return array.reduce((acc, cv) => {
    return (acc += parseFloat(cv.value));
  }, 0);
};

export const prefixStringWith0 = (str: string): string => {
  return ('0' + str).slice(-2);
};

export const typeToTypeString = (KPItype: string): string => {
  switch (KPItype) {
    case 'PERC':
      return ' %';
    case 'KNHA':
      return ' kg N/ha';
    case 'KCKF':
      return ' g Co2eq/kgFPCM';
    case 'KAHA':
      return ' kg NH3/ha';
    case 'KHA':
      return ' kg P205/ha';
    case 'UUR':
      return ' uur/jaar';
    case 'GKDS':
      return ' g RE/kg ds';
    case 'HA':
      return ' ha';
    case 'KG':
      return ' kg';
    default:
      return '';
  }
};

/**
 * Calculate percentage based on min and max values
 * @param y1 minimum vertical value default 100
 * @param y2 maximum vertical value default 0
 * @param x1 maximum horizontal value default 100
 * @param x2 minimum horizontal value default 0
 * @param value actual value
 * @returns percentage as number
 */
export const calcPercentage = ({ value, y1 = 100, y2 = 0, x1 = 100, x2 = 0 }: calcPercentageType): number => {
  if (x1 > x2) {
    value = value > x1 ? x1 : value;
    value = value < x2 ? x2 : value;
  } else {
    value = value < x1 ? x1 : value;
    value = value > x2 ? x2 : value;
  }
  const a = (y2 - y1) / (x2 - x1);
  const b = (x1 * y2 - x2 * y1) / (x1 - x2);
  return a * value + b;
};

export const mapItems = (items: Item[], points: Item[], averages: Avg[]): Item[] => {
  items.forEach((item) => {
    const pi = points.findIndex((pi) => {
      return pi.key.substr(0, pi.key.length - 5) === item.key;
    });
    const ai = averages.findIndex((ai) => {
      return ai.kpi === item.key;
    });
    const point = points[pi];
    const avg = averages[ai];
    if (point) item.points = parseFloat(point.value);
    if (avg) item.targetScore = avg.kpiValue;
  });
  return items;
};

export const getSubrewards = (rewards: Item[]): Item[] => {
  return rewards.filter((x) => x.key !== 'bbm_totaal_euro');
};

export const getTotal = (rewards: Item[]): RewardItem => {
  const total = rewards.find((x) => x.key === 'bbm_totaal_euro');
  if (total) {
    return total;
  }
  return { key: '', description: '', value: '0' };
};
const base64ToArrayBuffer = (data) => {
  const binaryString = window.atob(data);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

export const downloadPdf = (url, data, fileName = 'report.pdf') => {
  var pdfOptions = {
    format: 'A4',
    // margin: {
    //   top: '30px',
    //   right: '50px',
    //   bottom: '44px',
    //   left: '50px'
    // }
  };
  // @ts-ignore
  return fetch(`https://${window.HOST_PRINTSERVICE}/pdf`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({ url, data, pdfOptions, timeout: 1000 }),
  })
    .then((response) => response.text())
    .then((base64) => {
      const arrBuffer = base64ToArrayBuffer(base64);

      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      const newBlob = new Blob([arrBuffer], { type: 'application/pdf' });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);

      const link = document.createElement('a');
      document.body.appendChild(link); //required in FF, optional for Chrome
      link.href = data;
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(data);
      link.remove();
    });
};
