import { FooterBanner, InformativeBanner, NavBar, ScoreDescriptionList, Total } from 'components';
import { FC, useEffect, useState } from 'react';
import { prefixStringWith0 } from 'utilities/logic';

export const Result: FC = () => {
  const [data, setData] = useState({
    year: 0,
    reward: [],
    total: { key: '', description: '', value: '' },
    items: [],
    filteredInfo: [],
    greenItems: [],
    isLoading: false,
    startYear: 0,
    totalScore: 0,
    goals: [],
    userInfo: { naam: '', kvkNummer: '' },
  });

  useEffect(() => {
    (async () => {
      // @ts-ignore
      if (window.getData) {
        // @ts-ignore
        setData(await window.getData());
      }
    })();
  }, []);
  const now = new Date();
  const day = prefixStringWith0(now.getDate().toString());
  const month = prefixStringWith0((now.getMonth() + 1).toString());
  const fullYear = prefixStringWith0(now.getFullYear().toString());
  const date = `${day}-${month}-${fullYear}`;
  return (
    <div style={{ paddingBottom: '1%' }}>
      <NavBar isPrintedPDF={true} name={data?.userInfo.naam} kvk={data?.userInfo.kvkNummer} date={date} />
      <InformativeBanner
        year={data?.year}
        showPageDescription={false}
        aquiredScore={data?.totalScore}
        isLoading={data?.isLoading}
        scoreFooter={''}
        maxScore={2500}
      />
      <ScoreDescriptionList
        isLoading={data?.isLoading}
        scoreDescriptionListItems={data?.filteredInfo}
        subtitle="Algemene bedrijfsinformatie"
        isCompanyInfo={true}
      />
      <ScoreDescriptionList
        isLoading={data?.isLoading}
        scoreDescriptionListItems={data?.items}
        subtitle="Kringloopwijzer Indicatoren"
        title="Hoe is de score opgebouwd"
      />
      <div style={{ pageBreakAfter: 'always' }}></div>
      <ScoreDescriptionList
        isLoading={data?.isLoading}
        scoreDescriptionListItems={data?.greenItems}
        subtitle="Groene Indicatoren"
      />
      <Total isLoading={data?.isLoading} total={data?.totalScore} />
      <div style={{ pageBreakAfter: 'always' }}></div>
      <FooterBanner
        year={data?.year}
        startYear={data?.startYear}
        nextGoalEmpty={true}
        rewards={data?.reward}
        total={data?.total}
        isLoading={data?.isLoading}
        score={data?.totalScore}
        goals={data?.goals}
      />
    </div>
  );
};
